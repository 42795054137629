@font-face {
    font-family: AvenirLTStd-Heavy;
    src: url("../assets/fonts/AvenirLTStd-Heavy.otf");
}

@font-face {
    font-family: AvenirLTStd-Medium;
    src:  url("../assets/fonts/AvenirLTStd-Medium.otf");
}

@font-face {
    font-family: AvenirLTStd-Black;
    src: url("../assets/fonts/AvenirLTStd-Black.otf");
}

@font-face {
    font-family: AvenirLTStd-Book;
    src:  url("../assets/fonts/AvenirLTStd-Book.otf");
}

@font-face {
    font-family: AvenirLTStd-Light;
    src:  url("../assets/fonts/AvenirLTStd-Light.otf");
}


body {
    font-family: AvenirLTStd-Medium, Arial, sans-serif;
    font-size: 16px;
}

a{
    text-decoration: none;
    color: #0079C1;
}
a:active, a:hover {
    outline: 0;
}
a:focus, a:hover {
    color: #003767;
    text-decoration: underline;
}


h3 small {
    font-weight: 400;
    line-height: 1;
    color: #6A737B;
    font-size: 65%;
}


.blue{
    color: #0079C1;
}

.blue-str{
    color: #003767;
}

.center{
    text-align: center;
}

.btn-bg-str{
    background-color: #003767;
    border: none;
}

.btn-bg-disbale{
    background-color: #95c7f3;
    border: none;
}

.btn-bg-str:disabled{
    background-color: #003767;
    border: none;
    pointer-events: none;
    opacity: .65;
}

.btn-bg{
    background-color: #0079C1;
    border: none;
}

.btn-influencer{
    background-color:  #8d9da9; 
     /*background-color:  #83bad8;*/
    border: none;
    color: #fff;
}

/* .btn-influencer:hover{
    background-color: #003767 !important;
    color: #fff;
} */

.btn-primary:hover{
    /* background-color: #83bad8!important;*/
    background-color:  #83bad8; 
}

.nav-link:focus, .nav-link:hover {
    color: #0079C1;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #0079C1;
}

.cursor{
    cursor: pointer;
}

.right{
    text-align: right;
}

.aviso-privacidad, .underline{
text-decoration: underline;
}

.condiciones{
    font-size: 18px;
    font-weight: bolder;
}

.center-self{
    align-self:center;
    margin-top: 13px;
}
.btn-red, .btn-green{
     color: white;
     border: none;
}

.btn-red{
    background-color: #bb2d3b;   
}

.btn-green{
    background-color: #049b01;;
}

.btn-green:disabled{
    background-color: #5cc15a;
}
.red{
    color: #bb2d3b;
}

.green{
    color: #049b01;
}

.bold{
    font-weight: bold;
}

.warning{
    color: #372901;
    background-color: #fff3cd;
    border: 3px solid #372901;
}

.info-alert{
    color: #022b33;
    background-color: #cff4fc;
    border: 3px solid #022b33;
}

/**********************************************MENU****************************************************/


.menu-principal {
    background-color: #003767;
    padding: 15px;
    align-items: center;
}

.dropdown-item.active, .dropdown-item:active {

    background-color: #0079C1 !important;
}

.dx-widget{
    font-family: AvenirLTStd-Medium, Arial, sans-serif;
    /* font-size: 16px !important; */
}

.dx-icon-pdffile::before {
    font-size: 23px;
}

.dx-icon-download::before, .dx-icon-download::before {
    color: #003767;
}

.dx-icon-key::before {
    content: "\f033";
    font-size: 25px;
}

.navbar {
    padding-top: 0 ; 
    padding-bottom: 0;
}

.navbar-light .navbar-nav .nav-link {
    color: #003767 ;
}

.menu  {
    list-style-type:none;
    margin:0;
    position: absolute;
    padding: 0;
}
 .menu li {
    display:inline-block;
    float: left;
    margin-right: 1px;
}

.menu li a{
    display:block;
    min-width:105px;
    max-width: 120px;
    height: 50px;
    text-align: center;
    color: #003767;
    background: #f5f5f5;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
}

.menu li:hover a{
    background: #0079C1;
    color: #fff;
    text-decoration: none;
}

.show-menu {
    text-decoration: none;
    color: #fff;
    background: #0079C1;
    text-align: left;
    padding: 10px 20px;
    display: none;

}

.checkbox-menu {
    display: none;
}

input[type=checkbox]:checked ~ #menu{
    display: block;
}

a.activem {
    color: #fff !important;
    background-color: #0079C1 !important;
}

.pad-submenu{
    padding: 30px 0px;
}


@media screen and (max-width : 768px){
    .menu {
        position: static;
        display: none;
    }
    .menu li {
        margin-bottom: 1px;
    }
    .menu li, li a {
        width: 100%;
    }
    .show-menu {
        display:block;
    }
    .menu li a {
        max-width: 100%;
        justify-content:left;
        padding-left: 20px;
        height: 40px;
    }

    .pad-submenu {
        padding: 30px 0px 0px 0px;
    }
}

/***********************************************LOGIN**********************************************/

.split {
    height: 100%;
    width: 50%;
    position: fixed;
    z-index: 1;
    top: 0;
    overflow-x: hidden;
    padding-top: 20px;
}
  
.left-login {
    left: 0;
    background-color: #111;
    background-image: url('../assets/img/bg_valores.jpg'); 
    background-size: cover;
    background-repeat: no-repeat; 
    background-position: center;
}
  
.right-login {
    right: 0;
    background-color: #fff;
}
  
.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
}
  
#background {
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-image: url('https://ipgproveedores.com.mx/css/img/intro-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat; 
    z-index: 1;
}

.bg-white{
    background-color: white;
}

.abs-center {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
}
  
.form-login {
    width: 450px;
}

.title1{
    font-size: 2em;
    color: #0079C1;
}

.title2{
    font-size: 1.5em;
    color: #6A737B;
}

.btn-link {
    text-decoration: none;
    color: #0079C1;
 }
 .btn-link:hover {
     color: #003767;
     text-decoration: underline;
 }

 .red{
    color: #d90a0a !important;
 }

.version{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: right;
    padding-right: 30px;
    padding-top: 65px;
    color: #6A737B;
    font-size: 14px;
}

.languaje{
   
    bottom: 0;
    width: 100%;
    height: 100px;
    text-align: right;
    padding-right: 30px;
    color: #6A737B;
    font-size: 14px;
}
 
 

/*******************************************MODAL*********************************************/

.close{
    background: none;
    border: none;
    font-size: 25px;
    color: #6A737B;
}

/***************************************************FOOTER************************************/
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
   background-color: #fff;
  text-align: center;
}
.footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .footer-text {
    color: #6A737B;
    font-size: 14px;
  }
  
  footer ul {
    list-style-type: none;
    margin: 10px auto;
  }
  
  footer li {
    display: inline-block;
    color: #6A737B;
    font-size: 12px;
    margin-right: 10px;
  }
  
  footer li:last-child {
    margin-right: 90px;
  }

footer li a {
    display: block;
    border-right: 1px solid #a7a7a7;
    padding: 0 10px 0 10px;
    font-size: 12px;
    color: #6A737B !important;
}


.white{
    color:#fff;
}

.logout, .idiomas{
    text-align: end;
    margin-left: -45px;
}

.download-logs, .btns-edit, .end{
    text-align: end;
}

.idiomas-options{
    text-transform: capitalize !important;
    background-color: #003767;
    color: #FFF!important;
    border-radius: 20px;
    border: none;
}

.idiomas-home{
    text-transform: capitalize !important;
    /* background-color: #003767; */
    color: #003767;
    /* border-radius: 10px; */
    border: none;
    padding: 3px;
}

.logout a:hover {
    color: #fff;
    text-decoration: underline;
}
.hr-footer {
  margin: 0;
}

.btn-toogle-pills{
    background-color: #0079C1;

    border-radius: 5px;
}

.btn-toogle-pills>.dropdown-toggle{
    color:#fff;
}

.dropdown-item a{
    color: #000 !;
}

.alert-dark {
    color: #003767;
    background-color: #fdff8d;
    border-color: #f9d468;
}
/*******************************************FORMULARIO REGISTRO***************************/

.form-registro{
  width: 70%;
  padding-bottom: 30px;
}

.intro-registro {
  color: #6A737B;
  font-weight: bold;
  font-size: 24pt;
  text-align: center;
  margin: 20px 0px 40px;
}

.error {
    margin-top: 0;
    color: #d90a0a
}

.input-rfc{
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f2f2f2;
}
/***************************************************STEPS**********************************/

.buttons {
    display: flex;
    justify-content: center;
}
  
.buttons__button {
    width: 180px;
    padding: 10px;
    border-radius: 3px;
    border: 1px solid #0079C1;
    margin: 5px 12px;
}
  
.buttons__button:hover {
    
    box-shadow: 3px 2px 3px rgba(175, 175, 175, 0.4);
}
  
.buttons__button--next {
    background-color: #0079C1;
    color: #fff
}
.buttons__button--next:disabled{
    pointer-events: none;
    opacity: .65;
}

  
.buttons__button--back {
    background-color: #fff;
    color: #0079C1
}

.stepper-wrapper {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .stepper-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
  
    @media (max-width: 768px) {
      font-size: 12px;
    }
  }
  
  .stepper-item::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: -50%;
    z-index: 2;
  }
  
  .stepper-item::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 2;
  }
  
  .stepper-item .step-counter {
    position: relative;
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #ccc;
    margin-bottom: 6px;
    color: #fff;
  }
  
  .stepper-item.active {
    font-weight: bold;
  }
  
  .stepper-item.completed .step-counter {
    background-color: #0079C1;
  }
  
  .stepper-item.completed::after {
    position: absolute;
    content: "";
    border-bottom: 2px solid #0079C1;
    width: 100%;
    top: 20px;
    left: 50%;
    z-index: 3;
  }
  
  .stepper-item:first-child::before {
    content: none;
  }
  .stepper-item:last-child::after {
    content: none;
  }
  


/****************************************************TABLAS************************************************/
  .dx-header-row {
    background-color: #6A737B!important;
    color: #FFF;
}
.data-grid-container {
    height: auto; /* Altura dinámica según el contenido */
    max-height: 500px; /* Altura máxima para evitar que la tabla se expanda demasiado */
    overflow-y: auto; /* Habilitar desplazamiento vertical si la tabla es más larga que la altura máxima */
}

 .dx-icon-edit {
    color: #003767 !important ;
}

.dx-icon-check {
    color: green !important;
}

.dx-icon-clear, .dx-icon-clearsquare , .dx-icon-pdffile{
     color: #dd0000!important; 
}

.dx-icon-card {
    color: #003767 !important; 
    font-size: 22px !important;
}

.dx-header-filter-empty {
    /* color: rgba(149,149,149,.5); */
    color: #fff !important;
}

.dx-icon-import, .dx-icon-clearsquare, .dx-icon-pdffile, .dx-icon-arrowdown{
    text-decoration: underline !important;
    font-size: 19px !important;
}

.btn-next, .btn-ant{
    color: #003767;
    background-color: #ffffff;
    border-color: #ffffff;
    margin-top: -4px;
}

.label-page{
    width: 10%;
}
.select-records{
    width: 15%;
}


/*****************************************RESUMEN PROVEEDOR************************************/

.resumen-proveedor {
    width: 80%;
    border-radius: 10px;
    padding: 20px 20px 30px 20px;
}
.container-info-proveedores{
    padding: 0px 140px;
}
.panel-info-proveedores{
    border: 1px solid #ddd;
    border-radius: 4px;
    -webkit-box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
    padding: 30px 20px;
}
.content-info{
    padding: 30px 20px;
}
.content-info b{
    color: #2d3e58;
}
.info-proveedores, .gray{
    color: #6A737B;
}
.info-proveedores b{
color: #003767
}

.not-found{
    font-size: 70px;
    font-weight: bolder;
}

.btn-acciones {
    color: #fff;
   border: none;
   background-color:#003767 ;
}
/****************************************ORDENES DE COMPRA*************************/
.form-inline{
    display: flex;
    align-items: center;
}
.form-inline input{
    width: 15%;
}

.modal-620w{
    width: 620px;
    max-width: none!important;
}

/***************************************ESTILOS TIPO DROPZONE***************************************/

.files input {
    outline: 2px dashed rgba(0, 0, 0, 0.3);
    outline-offset: -2px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
    /* padding: 120px 0px 85px 35%; */
    padding: 50px 50px 30px;
    /* text-align: center !important; */
    margin: 0;
    width: 100% !important;
    background-color: #e9ecef;
}
.files input:focus{     outline: 2px dashed rgba(0, 0, 0, 0.3);  outline-offset: -2px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear; border:1px solid #003767;
 }
.files{ position:relative}
.files:after {  pointer-events: none;
    position: absolute;
    top: 60px;
    left: 0;
    width: 50px;
    right: 0;
    height: 56px;
    content: "";
    display: block;
    margin: 0 auto;
    background-size: 100%;
    background-repeat: no-repeat;
}
.color input{ background-color:#f1f1f1;}
.files:before {
    position: absolute;
    bottom: 30px;
    left: 50px;  
    pointer-events: none;
    width: 100%;
    right: 0;
    height: 57px;
    content: " Arrastrar y soltar ó";
    display: block;
    margin: 0 auto;
}


/****************************************FORMULARIO AÑADIR USUARIOS*************************/
.pad-30{
    padding: 30px;
}

.pad-50-top{
    padding-top: 50px;
}

.pad-40-t{
    padding-top: 40px;
}


/***********************************************loading**************************************/
.padre-transform{
    height: 100vh;
    min-height:600px;
  }

.padre-transform > div{
    position: absolute;
    top:50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


.loader {
    width: 20em;
    height: 20em;
    font-size: 10px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader .face {
    position: absolute;
    border-radius: 50%;
    border-style: solid;
    animation: animate 3s linear infinite;
}

.loader .face:nth-child(1) {
    width: 100%;
    height: 100%;
    color: #8C7F8C;
    border-color: currentColor transparent transparent currentColor;
    border-width: 0.2em 0.2em 0em 0em;
    --deg: -45deg;
    animation-direction: normal;
}

.loader .face:nth-child(2) {
    width: 70%;
    height: 70%;
    color: #9188ff;
    border-color: currentColor currentColor transparent transparent;
    border-width: 0.2em 0em 0em 0.2em;
    --deg: -135deg;
    animation-direction: reverse;
}

.loader .face .circle {
    position: absolute;
    width: 50%;
    height: 0.1em;
    top: 50%;
    left: 50%;
    background-color: transparent;
    transform: rotate(var(--deg));
    transform-origin: left;
}

.loader .face .circle::before {
    position: absolute;
    top: -0.5em;
    right: -0.5em;
    content: '';
    width: 1em;
    height: 1em;
    background-color: currentColor;
    border-radius: 50%;
    box-shadow: 0 0 2em,
                0 0 4em,
                0 0 6em,
                0 0 8em,
                0 0 10em,
                0 0 0 0.5em rgba(255, 255, 0, 0.1);
}

@keyframes animate {
    to {
        transform: rotate(1turn);
    }
}

/****************************************SFTP  AZURE*****************************/
.btn-sftp{
    max-width: 255px;
    width: 255px;
    color: #fff;
    text-align: left;
}
.btn-sftp:hover{
opacity: 0.9;
color: #fff;
}

.btn-azul {
    background-color: #0079C1;
    border-color: #0079C1;
}

.btn-verde {
    background-color: #049b01;
    border-color: #049b01;
}

.btn-rojo {
    background-color: #bb2d3b;
    border-color: #bb2d3b;
}

.btn-SFTP3 {
    background-color: #d37e04;
    border-color: #d37e04;
}

/****************************************checkbox*************************/


.iti--allow-dropdown input,.iti--allow-dropdown input[type=tel]{
    padding-right: 6px;
    padding-left: 52px;
    padding-top: 6px;
    padding-bottom: 7px;
    margin-left: 0;
    margin-left: 0;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



.option-input {
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    position: relative;
    top: 7.33333px;
    right: 0;
    bottom: 0;
    left: 0;
    height: 25px;
    width: 25px;
    transition: all 0.15s ease-out 0s;
    background: #b1b7bd;
    border: none;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    margin-right: 0.5rem;
    outline: none;
    position: relative;
    /* z-index: 1000; */
  }
  .option-input:hover {
    background: #b1b7bd;
  }
  .option-input:checked {
    background: #003767;
  }
  .option-input:checked::before {
    width: 24px;
    height: 24px;
    display:flex;
    content: '\f00c';
    font-size: 24px;
    font-weight:bold;
    position: absolute;
    align-items:center;
    justify-content:center;
    font-family:'Font Awesome 5 Free';
  }
  .option-input:checked::after {
    -webkit-animation: click-wave 0.65s;
    -moz-animation: click-wave 0.65s;
    animation: click-wave 0.65s;
    background: #003767;
    content: '';
    display: block;
    position: relative;
    z-index: 100;
  }
  .option-input.radio {
    border-radius: 50%;
  }
  .option-input.radio::after {
    border-radius: 50%;
  }
  
  
  /*************************************SEGURIDAD*********************************/
  .switchBtn {
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;
}
.switchBtn input {display:none;}
.slide {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 5px 13px;
    color: #fff;
}
.slide:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 60px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slide {
    background-color: #8CE196;
    padding-left: 40px;
}
input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
}
input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -20px;
}

.slide.round {
    border-radius: 34px;
}
.slide.round:before {
    border-radius: 50%;
}


.center-v{
    align-items: center;
    display: flex;
}

.center-2{
    align-content: center;
}


/****************************************MEDIA QUERIES*************************/


@media (max-width: 768px) {
    .form-inline input{
        width: 100%;
    }
    .split {
        height: 100%;
        width: 100%;

    }
    .centered {
        position: relative;
    }
    .form-login {
        width: 100%;
    }
    .step-name{
        display: none;
    }

    .version {
        /* position: relative; */
        height: 10px;
        padding-top: 20px;
    }

    .languaje{
        display: none;
    }

    .label-page{
        width: 50%;
    }
    .select-records{
        width: 50%;
    }
}


.dx-scrollbar-hoverable .dx-scrollable-scroll.dx-state-invisible .dx-scrollable-scroll-content {
    background-color: #cecece !important;
    -webkit-box-shadow: 0 0 0 1px transparent;
    box-shadow: 0 0 0 1px transparent;
}

